import { cva } from 'class-variance-authority';

export const Container = cva(
  [
    'inline-flex',
    'items-center',
    'justify-center',
    'gap-1.5',
    'py-1.5',
    'px-3',
    'rounded-md',
    'text-xs',
    'font-medium',
  ],
  {
    variants: {
      type: {
        info: ['bg-brand-100', 'text-brand-pure'],
        danger: ['bg-driver-centricity-100', 'text-driver-centricity-800'],
        success: [
          'bg-sustainability-green-100',
          'text-sustainability-green-900',
        ],
        ghost: ['border', 'border-blue-200', 'text-blue-500'],
        warning: ['bg-yellow-100', 'text-yellow-800'],
      },
    },
  },
);

export const RemoveButton = cva(
  [
    'flex-shrink-0',
    'h-4',
    'w-4',
    'inline-flex',
    'items-center',
    'justify-center',
    'rounded-full',

    'focus:outline-none',
  ],
  {
    variants: {
      type: {
        info: [
          'text-blue-600',
          'hover:bg-blue-200',
          'hover:text-blue-500',
          'focus:bg-blue-200',
          'focus:text-blue-500',
        ],
        danger: [
          'text-red-600',
          'hover:bg-red-200',
          'hover:text-red-500',
          'focus:bg-red-200',
          'focus:text-red-500',
        ],
        success: [
          'text-sustainability-green-600',
          'hover:bg-sustainability-green-200',
          'hover:text-sustainability-green-500',
          'focus:bg-sustainability-green-200',
          'focus:text-sustainability-green-500',
        ],
        ghost: [
          'text-blue-600',
          'hover:bg-blue-200',
          'hover:text-blue-500',
          'focus:bg-blue-200',
          'focus:text-blue-500',
        ],
        warning: [
          'text-yellow-600',
          'hover:bg-yellow-200',
          'hover:text-yellow-500',
          'focus:bg-yellow-200',
          'focus:text-yellow-500',
        ],
      },
    },
  },
);

export const RemoveIcon = cva(['h-4', 'w-4'], {
  variants: {
    type: {
      info: ['text-brand-pure'],
      danger: ['text-driver-centricity-800'],
      success: ['text-sustainability-green-800'],
      ghost: ['text-blue-500'],
      warning: ['text-yellow-500'],
    },
  },
});

import { cva } from 'class-variance-authority';

export const container = cva([
  'font-inter',
  'w-full',
  'sm:pb-5',
  'flex',
  'justify-between',
  'items-start',
]);

export const heading = cva(['flex', 'flex-col', 'gap-4']);

export const title = cva([
  'sm:text-2xl',
  'md:text-3xl',
  'text-xl',
  'font-semibold',
  'text-brand-800',
  'mb-3',
  'sm:mb-0',
]);

export const breadcrumbContainer = cva([
  'md:flex',
  'gap-5',
  'items-center',
  'hidden',
]);

export const actions = cva(['flex', 'justify-center', 'items-center', 'gap-4']);

export const actionsWrapper = cva([
  'flex',
  'gap-4',
  'sm:items-end',
  'mb-1',
  'items-start',
]);

import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import * as styles from './styles';

export type BadgeProps = {
  LeadingIcon?: ReactNode;
  title: string;
  type?: 'info' | 'danger' | 'success' | 'ghost' | 'warning';
  onRemove?: () => void;
};

export function Badge({
  LeadingIcon,
  title,
  type = 'info',
  onRemove,
}: BadgeProps) {
  const { t } = useTranslation(['common']);

  return (
    <span className={styles.Container({ type })}>
      {LeadingIcon && LeadingIcon}
      {title}
      {onRemove && (
        <button
          type="button"
          className={styles.RemoveButton({ type })}
          onClick={onRemove}
        >
          <span className="sr-only">{t('remove')}</span>
          <svg
            className={styles.RemoveIcon({ type })}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </button>
      )}
    </span>
  );
}

import { ICreateCustomerInDeal } from './Customer';
import { DiscountStatusEnum, DiscountTypeEnum } from './Discount';
import { IModelPayload } from './Model';
import { OperationTypeEnum } from './Operation';
import { IDealPlanAndProduct, IPlanPayload } from './Plan';
import { IStore } from './Store';
import { TypeEngineEnum } from './TypeEngine';
import { IUser } from './User';
import { VehicleCategoryEnum } from './Vehicle';

export enum DealStageEnum {
  SKETCH = 'SKETCH',
  PRICE = 'PRICE',
  PROPOSAL = 'PROPOSAL',
}

export enum DealStatusEnum {
  FILLING_IN = 'FILLING_IN',
  REVIEW_REQUESTED = 'REVIEW_REQUESTED',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  WINNER = 'WINNER',
  LOST = 'LOST',
  CHURN = 'CHURN',
  CANCELED = 'CANCELED',
}

export enum DealProductStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum DealProductPriceStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface IDealPayload {
  id: string;
  dealCode?: string;
  storeId: string;
  planId: string;
  userSellerId: string;
  customerId?: string;
  customer?: ICreateCustomerInDeal | null;
  headquartersCode?: string;
  subsidiaryCode?: string;
  title: string;
  tags?: string[];
  totalQuantityProducts?: number;
  totalDealAmount?: number;
  dealStage: DealStageEnum;
  description?: string;
  status: DealStatusEnum;
  createdAt: string;
  updatedAt: string;
  isActive?: boolean;
  Plan: IPlanPayload;
  DealProduct: IDealProductPayload[];
}

export interface IDealPlanProduct {
  id: string;
  storeId: string;
  planId: string;
  userSellerId: string;
  customerId?: string;
  customer?: ICreateCustomerInDeal | null;
  headquartersCode?: string;
  subsidiaryCode?: string;
  title: string;
  tags?: string[];
  totalQuantityProducts?: number;
  totalDealAmount?: number;
  dealStage: DealStageEnum;
  status: DealStatusEnum;
  createdAt: string;
  updatedAt: string;
  isActive?: boolean;
  Store: IStore;
  Plan: IDealPlanAndProduct;
}

export interface IDealProductPayload {
  id: string;
  dealId: string;
  productModelId: string;
  vehicleCategory: VehicleCategoryEnum;
  planProductFrequencyTitle?: string;
  operationType?: OperationTypeEnum;
  typeEngine?: TypeEngineEnum;
  frequencyValueInterval?: number;
  quantityOfChassis: number;
  monthlyMileage: number;
  totalContractTimeInMonths: number;
  totalContractTimeInYears: number;
  totalAnnualMileage: number;
  totalMileageForTheContractPeriod: number;
  traction?: string;
  gearbox?: string;
  truckConfiguration?: string;
  transportSegment?: string;
  topographicCondition?: string;
  transportFactor?: string;
  offRoadPercentage?: string;
  roadConditions?: string;
  trafficConditions?: string;
  dustPresence?: string;
  useOfPTO?: string;
  axisConfiguration?: string;
  dateAddedOfProduct: Date | string;
  isVehicleZero: boolean;
  initialMileage: number;
  requiresAbsolutePriceCalculation?: boolean;
  contractedEveryRevisionExchange?: boolean;
  groupFailures?: string[];
  status: DealProductStatusEnum;
  createdAt: string;
  updatedAt: string;
  isActive?: boolean;
  DealProductPrice?: IDealProductPricePayload[];
  ProductModel: IModelPayload;
}

export interface IDealProductPlanProduct {
  id: string;
  dealId: string;
  productModelId: string;
  vehicleCategory: VehicleCategoryEnum;
  operationType?: OperationTypeEnum;
  typeEngine?: TypeEngineEnum;
  warrantyStartDate: string | null;
  frequencyValueInterval?: number;
  quantityOfChassis: number;
  monthlyMileage: number;
  totalContractTimeInMonths: number;
  totalContractTimeInYears: number;
  totalAnnualMileage: number;
  totalMileageForTheContractPeriod: number;
  traction?: string;
  gearbox?: string;
  truckConfiguration?: string;
  transportSegment?: string;
  topographicCondition?: string;
  transportFactor?: string;
  offRoadPercentage?: string;
  roadConditions?: string;
  trafficConditions?: string;
  dustPresence?: string;
  useOfPTO?: string;
  axisConfiguration?: string;
  dateAddedOfProduct: Date | string;
  isVehicleZero: boolean;
  initialMileage: number;
  requiresAbsolutePriceCalculation?: boolean;
  contractedEveryRevisionExchange?: boolean;
  groupFailures?: string[];
  status: DealProductStatusEnum;
  createdAt: string;
  updatedAt: string;
  isActive?: boolean;
  Deal: IDealPlanProduct;
}

export interface IDealProductPricePayload {
  id: string | null;
  dealProductId: string;
  isAbsolutePricing?: boolean;
  quantityOfChassis: number;
  totalContractTimeInMonths: number;
  totalAnnualMileage?: number;
  totalMileageForTheContractPeriod?: number;
  dateOriginalPrice?: Date | string | null;
  unitPrice: number;
  totalPriceOfOptions?: number;
  totalDiscountAmount?: number;
  discountRate?: number;
  totalPrice: number;
  totalPricePerKilometer: number;
  monthlyInstallmentPrice: number;
  feeRate?: number;
  totalFeeAmount?: number;
  taxRate?: number;
  totalTaxAmount?: number;
  bonusRate?: number;
  totalBonusAmount?: number;
  diffBetweenTechnicalAndPublicPrice?: number;
  userSellerId: string;
  absolutePricing?: any;
  status?: DealProductPriceStatusEnum;
  isActive?: boolean;
  createdAt: string;
  updatedAt: string;
  DealProductPriceDiscount: IDealProductPriceDiscountPayload[];
}

export interface IDealProductPriceDiscountPayload {
  id: string | null;
  dealProductPriceId: string;
  discountType: DiscountTypeEnum;
  discountAmount: number;
  requestedByUserId: string;
  requestedAt: Date | string;
  reasonForDiscount?: string;
  managedByUserId?: string;
  managedAt?: Date | string;
  status: DiscountStatusEnum;
  isActive?: boolean;
  createdAt: string;
  updatedAt: string;
  User: IUser;
  UserManager: IUser;
}

export interface IDealProductPriceDiscountRequest {
  dealId: string;
  dealProductId: string;
  discountType: DiscountTypeEnum;
  discountAmount: number;
  requestedByUserId: string;
  reasonForDiscount?: string | null;
  status: DiscountStatusEnum;
}

export interface ICreatedDeal {
  id: string;
  customerId: string | null;
  userSellerId: string;
  planId: string;
  storeId: string;
  title: string;
  dealStage: DealStageEnum.SKETCH;
  status: DealStatusEnum.FILLING_IN;
  subsidiaryCode: string | null;
  headquartersCode: string | null;
  description?: string;
  totalDealAmount: number | null;
  totalQuantityProducts: number | null;
  tags: string[];
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface ICloneDeal {
  dealId: string;
  userSellerId: string;
  title: string;
  tags?: string[] | null;
}

export type IEditDeal = Partial<ICreatedDeal>;

export type IEditDealDrawer = {
  title: string;
  tags?: string[];
  description?: string;
  userSellerId: string;
  storeId: string;
  planId: string;
};
export interface IDealCommentPayload {
  id: string;
  dealCode?: string;
  dealId: string;
  userId: string;
  message: string;
  commentedAt: Date | string;
  isPinnedComment: boolean;
  internalEntityId?: string;
  internalAction?: string;
  updatedAt: string;
  createdAt: string;
  isActive: boolean;
  User: IUser;
}

export interface ICreateDealComment {
  dealId: string;
  message: string;
}

export interface IEditDealComment {
  dealId: string;
  userId: string;
  message: string;
}

export interface IDealLost {
  id: string;
  lossReason: string;
}

export interface IDealAssess {
  id: string;
  isApproved: boolean;
  reviewMessage: string | null;
}

export interface IDealReview {
  id: string;
  reasonForReview: string | null;
}

export interface IDealProductCostPricePayload {
  id: string;
  dealProductId: string;
  quantityOfChassis: number;
  totalContractTimeInMonths: number;
  totalAnnualMileage?: number;
  totalMileageForTheContractPeriod?: number;
  totalWarrantyMileage?: number;
  totalWarrantyYears?: number;
  totalQuantityOfReviews?: number;
  totalQuantityOfLabor?: number;
  totalQuantityOfLubricants?: number;
  unitTechnicalPrice: number;
  totalTechnicalPrice: number;
  totalTechnicalPricePerKilometer: number;
  unitPublicPrice?: number;
  totalPublicPrice?: number;
  totalPublicPricePerKilometer?: number;
  totalCostOfOptions?: number;
  userSellerId: string;
  status?: DealProductPriceStatusEnum;
  isActive?: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface IPrintDealRequest {
  id: string;
  customerDocumentNumber?: string | null;
  customerName?: string | null;
  customerNameResponsible?: string | null;
  customerEmail?: string | null;
  customerPhone?: string | null;
  formPayment?: string | null;
}

import { DealStatusEnum } from '@/@types/Deal';
import { z } from 'zod';

export const NewProposalSchema = z.object({
  dealerId: z
    .object({
      label: z.string(),
      value: z.string(),
    })
    .nullable()
    .refine(data => data !== null && data.value !== '')
    .transform(val => val?.value),
  storeId: z
    .object({
      label: z.string(),
      value: z.string().nonempty(),
    })
    .nullable()
    .refine(data => data !== null && data.value !== '')
    .transform(val => val?.value),
  userSellerId: z
    .object({
      label: z.string(),
      value: z.string().nonempty(),
    })
    .nullable()
    .refine(data => data !== null && data.value !== '')
    .transform(val => val?.value),
  planId: z.string().optional(),
  title: z.string().nonempty(),
  description: z.string().optional(),
  tags: z
    .array(
      z
        .object({
          value: z.string().nonempty(),
        })
        .transform(val => val.value),
    )
    .min(0)
    .nullable(),
  customerId: z.string().nullable().default(null),
  headquartersCode: z.string().nullable().default(null),
  subsidiaryCode: z.string().nullable().default(null),
  dealStage: z.string().nullable().default(null),
  status: z.nativeEnum(DealStatusEnum).default(DealStatusEnum.FILLING_IN),
  totalDealAmount: z.number().nullable().default(0),
  totalQuantityProducts: z.number().nullable().default(0),
});

export const NewProposalPlanSchema = NewProposalSchema.extend({
  planId: z.string().nonempty(),
  dealerId: z.string().nonempty(),
  storeId: z.string().nonempty(),
  userSellerId: z.string().nonempty(),
  tags: z.array(z.string()).nullable(),
});

export type NewProposalForm = z.infer<typeof NewProposalSchema>;

export type NewProposalPlanForm = z.infer<typeof NewProposalPlanSchema>;
